body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

small {
  font-size: 10px !important;
}
svg {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.wrapper-with-lid svg {
  margin: 0px 30px;
}
.rectangle {
  fill: rgba(200,200,200,0.5);
}
.form-short input {
  max-width: 100%;
}
.form-group {
  margin-bottom: .4rem !important;
}
.alert-default {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.alert.small-box {
  line-height:1.5 !important;
  padding-top:0.3rem !important;
  padding-bottom:0.4rem !important;
}
.alert.xs-box {
  line-height:1 !important;
  padding-top:0.3rem !important;
  padding-bottom:0.4rem !important;
  margin-bottom:0.4rem !important;
}
.design-tabs .nav-link {
  padding:0.2rem 1rem !important;
}
.line-label {
  font-size: 14px;
}
.line-input-field {
  padding-left:6px !important;
  padding-right:1px !important;
  height: calc(1em + 0.75rem + 2px) !important;
  line-height: 1 !important;
}
.border-bottom {
  border-bottom: '1px #999 solid';
  padding-bottom: 4px;
  margin-bottom: 4px;
}
.text-medium {
  font-size: 14px;
}
.text-small {
  font-size: 11px;
}
header.top {
  text-align: 'center';
  color: #eee;
  background-color: #0f0f0f;

}
header.top h1 {
  color: #eee;
  display: flex;
  justify-content: 'center';
}
header.top h1 > a {
  color: #eee;
}
header.top h1 > a:hover {
  color: #007bff;
}
header.top a:hover {
  text-decoration: none;
}
header.top .tagline {
  font-size: 20px;
  padding-top: 14px;
}
header .nav-links {
  color: #fff;
  font-size: 20px;
  padding: 0px;
}
footer {
  padding-bottom: 20px;
  border-top: 1px solid #eee;
  /* background-image:
    linear-gradient(
   #ffffff, #ffffff, #eee
    ); */
}
footer .footer-alert {
  margin:20px auto;
  padding:0px 10px 5px;
  text-align: center;
}
.input-danger:focus {
  color: #dc3545!important; /* #495057; */
  background-color: #fff8f8 !important;
  border-color: #dc3545!important; /* #80bdff; */
  box-shadow: 0 0 0 0.2rem rgba(252, 214, 214, 0.25) !important;
}
.depaddify {
  padding: 0px !important;
}
/* Loading spinner divs */
.loading {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index:10001;
}
.loading-wheel {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 30px;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation:spin 1s ease infinite;
  animation:spin 1s ease infinite;
}
.loading-message {
  position: absolute;
  width: 39%;
  top: 55%;
  left: 33%;
  z-index: 2000;
  color: #ddd;
}
.style-2 .loading-wheel {
  border-style: double;
  border-color: #ccc transparent;
}
.css-1hb7zxy-IndicatorsContainer {
  max-width: 33%;
}
.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
  max-width: 50px;
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0);
  }
  100% {
      -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
}
100% {
    -webkit-transform: rotate(-360deg);
}
}